import React, { useEffect, useRef, useState } from "react";
import iconSpro from "../assets/spro.svg";
import appStore from "../assets/appStore.svg";
import playStore from "../assets/playStore.svg";
import iphone from "../assets/iphoneS.svg";
import ignicao from "../assets/ignicao.svg";
import pointMapa from "../assets/pointMapa.svg";
import historico from "../assets/historic.svg";
import velocidade from "../assets/velocidade.svg";
import cerca from "../assets/cercaVirtual.svg";
import bloqueio from "../assets/Bloqueio.svg";
import iphoneColors from "../assets/iphoneXmultiColo.svg";
import qualidades from "../assets/qualidadesSpro.svg";
import textGrup from "../assets/grupTextSpro.svg";
import textGrupMaior from "../assets/textGrupMaior.svg";
import qualidadesResponsiva from "../assets/qualidadesResponsive.svg";
import "./Spro.css";

export default function Spro() {
  const [hasBeenVisible, setHasBeenVisible] = useState(false);
  const iphoneColorsMobileRef = useRef(null);
  const iphoneColorsDesktopRef = useRef(null);

  const handleScroll = () => {
    const mobileContainer = iphoneColorsMobileRef.current;
    const desktopContainer = iphoneColorsDesktopRef.current;
    const visibleAmount = window.innerHeight * 0.5;

    let isVisible = false;

    if (mobileContainer) {
      const rect = mobileContainer.getBoundingClientRect();
      isVisible = rect.top <= window.innerHeight - visibleAmount && rect.bottom >= visibleAmount;
    }

    if (!isVisible && desktopContainer) {
      const rect = desktopContainer.getBoundingClientRect();
      isVisible = rect.top <= window.innerHeight - visibleAmount && rect.bottom >= visibleAmount;
    }

    setHasBeenVisible(isVisible);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll(); 

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="flex items-center text-center justify-center md:justify-between w-full">
        <div className="md:ml-24 p-6">
          <h3 className="font-medium text-sm md:text-left md:text-xl mb-3">
            Leve a sua empresa para um novo patamar com
          </h3>
          <h1 className="font-bold text-3xl md:text-5xl md:text-left titlePlataforma mx-4 md:mx-0 md:tracking-tighter">
            NOSSO APLICATIVO S-PRO
          </h1>
          <div className="flex mt-4 md:mt-8">
            <div className="md:w-4/12">
              <img className="w-8/12" src={iconSpro} alt="Icon S-Pro" />
            </div>
            <div className="flex">
              <a
                target="_blank"
  
                href="https://apps.apple.com/br/app/s-pro/id1458113409"
              >
                <img className="w-10/12" src={appStore} alt="AppStore" />
              </a>
              <a
                target="_blank"
  
                href="https://play.google.com/store/apps/details?id=br.com.appselsyn.spro&hl=pt&pli=1"
              >
                <img className="w-10/12" src={playStore} alt="PlayStore" />
              </a>
            </div>
          </div>
        </div>
        <div className="hidden md:block w-9/12 ml-10">
          <img className="w-10/12" src={iphone} alt="" />
        </div>
      </div>
      <div className="bg-frota">
        <div className="">
          <h1 className="font-bold text-2xl md:text-4xl text-center pt-10 md:pt-20 text-white">
            TENHA O CONTROLE DA SUA FROTA
          </h1>
        </div>
        <div className="grid grid-cols-3 text-white py-16 md:py-32">
          <div className="flex flex-col items-center">
            <img className="w-6/12 md:w-2/12" src={ignicao} alt="" />
            <p className="text-sm md:text-lg text-center md:w-44">
              Receba <strong>alertas de ignição</strong>
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-6/12 md:w-2/12" src={pointMapa} alt="" />
            <p className="text-sm md:text-lg text-center md:w-52">
              Acompanhe em <strong>tempo real a posição</strong>
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-6/12 md:w-2/12" src={historico} alt="" />
            <p className="text-sm md:text-lg text-center md:w-52">
              <strong>Histórico do trajeto</strong> na palma da sua mão
            </p>
          </div>
          <div className="flex flex-col items-center mt-10">
            <img className="w-6/12 md:w-2/12" src={velocidade} alt="" />
            <p className="text-sm md:text-lg text-center md:w-40">
              Acompanhe a <strong>velocidade</strong>
            </p>
          </div>
          <div className="flex flex-col items-center mt-10">
            <img className="w-6/12 md:w-2/12" src={cerca} alt="" />
            <p className="text-sm md:text-lg text-center md:w-40">
              Visualize as <strong>cercas virtuais</strong>
            </p>
          </div>
          <div className="flex flex-col items-center mt-10">
            <img className="w-6/12 md:w-2/12" src={bloqueio} alt="" />
            <p className="text-sm md:text-lg text-center md:w-52">
              <strong>Bloqueie seu veiculo</strong> a qualquer momento
            </p>
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex justify-center mt-5 md:mt-0 md:items-center md:h-screen md:gap-16 md:px-20">
          <img
            className={`hidden md:block w-4/12 transition-opacity duration-500 ${hasBeenVisible ? "opacity-100" : "opacity-0"}`}
            src={iphoneColors}
            alt=""
            ref={iphoneColorsDesktopRef}
          />

          <div className="w-full">
            <h1 className="font-bold text-2xl md:text-4xl titlePlataforma text-center md:text-left mb-10">
              TENHA SEU APP PERSONALIZADO
            </h1>
            <div className="block ml-0">
              <p className="w-11/12 mx-4 md:mx-0 md:w-full font-semibold whitespace-break-spaces">
                <span className="text-sm md:text-lg">
                  Com base nas suas necessidades e especificações, incorporamos os elementos
                </span>
                <span className="text-sm md:text-lg">
                  &nbsp;fundamentais que destacam a identidade da sua empresa.
                </span>
                <span className="text-sm md:text-lg">
                  <span className="titlePlataforma font-bold text-sm md:text-lg">
                    &nbsp; Investir nessa personalização é estratégico para se destacar no mercado de rastreamento
                  </span>
                  , atraindo mais clientes e construindo uma imagem sólida.
                </span>
              </p>
              <div className="w-full flex justify-center md:hidden">
                <img className="w-11/12" src={textGrupMaior} alt="" />
              </div>
            </div>
            <div className="flex md:hidden mt-8">
              <div className="w-full my-10">
                <img
                  className={`block md:hidden transition-opacity duration-500 ${hasBeenVisible ? "opacity-100" : "opacity-0"}`}
                  src={iphoneColors}
                  alt=""
                  ref={iphoneColorsMobileRef}
                />
              </div>
              <div className="flex flex-col items-center">
                <div className="w-9/12 mt-10">
                  <img src={qualidadesResponsiva} alt="" />
                </div>
                <a href="https://api.whatsapp.com/send?phone=5548991040129&text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site.%20Quero%20meu%20app%20personalizado.%20">
                  <button className="text-gradientButton text-xs py-1 px-2 rounded-full mb-5 font-bold mt-5 hover:scale-110 duration-500">
                    Quero meu App Personalizado
                  </button>
                </a>
              </div>
            </div>
            <div className="hidden md:flex justify-end mt-10">
              <img className="w-8/12" src={qualidades} alt="Qualidades" />
            </div>
          </div>
        </div>
        <div className="hidden md:flex w-full flex-col justify-center items-center">
          <img className="w-7/12" src={textGrup} alt="" />
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5548991040129&text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site.%20Quero%20meu%20app%20personalizado.%20"
          >
            <button className="text-gradientButton py-4 px-8 rounded-full font-bold my-16 hover:scale-110 duration-500">
              Quero meu App Personalizado
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
