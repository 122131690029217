import React from "react";
import MacBookReto from "../assets/macBookReto.svg";
import iconPlat from "../assets/icon-Plat.svg";
import iconOne from "../assets/iconOnePlat.svg";
import iconTwo from "../assets/iconTwoPlat.svg";
import iconTree from "../assets/IconTreePlat.svg";
import Funcionalidade from "../assets/Funcionalidades.svg";
import FuncionalidadeResp from "../assets/funcResponsivo.svg";
import dashBoards from "../assets/dashS.svg";

import "./Plataforma.css";

export default function Plataforma() {
  return (
    <>
      <div className="z-0 flex items-center  justify-around  ">
        <div className=" md:ml-24 p-6">
          <h3 className="font-medium text-center md:text-start text-sm md:text-xl mb-3">
            O software ideal para sua empresa:
          </h3>
          <h1 className="font-bold  text-3xl text-center md:text-start md:text-5xl titlePlataforma tracking-tighter md:w150">
            PLATAFORMA SELSYN DE <span>RASTREAMENTO E TELEMETRIA</span>
          </h1>
          <div className="w-full  flex justify-center md:flex md:justify-start mt-4">
            <img className=" w-40" src={iconPlat} alt="" />
          </div>
        </div>
        <div className=" hidden md:block w-full md:w-8/12">
          <img className="w-full" src={MacBookReto} alt="MacBook" />
        </div>
      </div>
      <div className="z-0 bg-Plat flex justify-center items-center gap-3 md:gap-20">
        <div className="my-20 bg-white p-2 md:p-10   md:h-72 md:w-64 flex flex-col justify-center items-center gap-5 rounded-xl">
          <img className="mt-2 w-4 md:w-8" src={iconOne} alt="" />
          <div className="bg-blue-600  h-00 rounded-lg w-20"> </div>
          <div className="titlePlataforma font-bold  text-sm md:text-2xl text-center tracking-tighter">
            <p>RELÁTORIOS</p>
            <span>COMPLETOS</span>
          </div>
        </div>
        <div className="my-20 bg-white p-2  md:p-10  md:h-72 md:w-64 flex flex-col justify-center items-center gap-5 rounded-xl">
          <img className="mt-2 w-5 md:w-10" src={iconTwo} alt="" />
          <div className="bg-blue-600  h-00 rounded-lg w-20 "> </div>
          <div className="titlePlataforma font-bold  text-sm md:text-2xl text-center tracking-tighter">
            <p>DASHBOARD</p>
            <span>MODERNO</span>
          </div>
        </div>
        <div className="my-20 bg-white p-2 md:p-10  md:h-72 md:w-64 flex flex-col justify-center items-center gap-5 rounded-xl">
          <img className="mt-2 w-6 md:w-12" src={iconTree} alt="" />
          <div className="bg-blue-600  h-00 rounded-lg w-20"> </div>
          <div className="titlePlataforma font-bold  text-sm md:text-2xl text-center w150 tracking-tighter">
            <p>CONTROLE DOS</p>
            <span>DISPOSITIVOS</span>
          </div>
        </div>
      </div>
      <div>
        <h1 className="titlePlataforma font-bold text-2xl mx-3 md:mx-0 md:textPerso tracking-tighter text-center mt-10 md:mt-20">
          O DASHBOARD PERFEITO PARA SUA GESTÃO
        </h1>
        <div className="w-full my-2 flex justify-center md:hidden">
          <img className="w-10/12" src={FuncionalidadeResp} alt="" />
        </div>
        <div className="hidden w-full md:flex items-center justify-center mt-10">
          <img className="w-10/12" src={Funcionalidade} alt="" />
        </div>
        <div className="itens-center justify-center flex my-10 md:mt-32">
          <a href="https://api.whatsapp.com/send?phone=5548991040129&text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site.%20Quero%20montar%20minha%20central%20e%20conhecer%20mais%20a%20plataforma.">
            <button className="text-gradientButton font-bold px-8 py-4 rounded-full hover:scale-110  duration-500">
              Adquirir a plataforma
            </button>
          </a>
        </div>
      </div>
      <div className=" w-full hidden md:flex items-center justify-center my-12">
        <img src={dashBoards} className="w-9/12" alt="" />
      </div>
    </>
  );
}
