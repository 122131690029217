import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Contato from "./pages/Contato";
import Mentoria from "./pages/Mentoria";
import Plataforma from "./pages/Plataforma";
import Spro from "./pages/Spro";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/Contato",
        element: <Contato />,
      },
      {
        path: "/Mentoria",
        element: <Mentoria />,
      },
      {
        path: "/Plataforma",
        element: <Plataforma />,
      },
      {
        path: "/Spro",
        element: <Spro />,
      },
    ],
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
